
import * as Api from "@/types/graphql";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CompaniesTable from "@/components/CompaniesTable.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { State as StateStore } from "../store";
import { CompaniesListTypes } from "@/store/modules/data";

@Component({
  components: { Btn, Card, CreateUserModal, CompaniesTable },
})
export default class DatabaseCompaniesView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }
}
